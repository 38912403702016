import { createStore } from '@cobuildlab/react-simple-state';
import { MONTHS } from '../../shared/constants';
import moment from 'moment';

export const subscriptionTableFilterStore = createStore<{
  status: string;
  paymentMethod: string;
}>({
  initialValue: {
    status: 'all',
    paymentMethod: 'all',
  },
});

export const subscriptionNextExpireTableFilterStore = createStore<{
  monthExpire: MONTHS;
  status: string;
  paymentMethod: string;
}>({
  initialValue: {
    monthExpire: moment().locale('en').format('MMMM').toUpperCase() as MONTHS,
    status: 'ACTIVE',
    paymentMethod: 'all',
  },
});
