import { createStore } from '@cobuildlab/react-simple-state';
import moment from 'moment/moment';
import momentTimezone from 'moment-timezone';
import { DEFAULT_TIMEZONE } from '../../shared/constants';

export const currentTime = momentTimezone()
  .tz(DEFAULT_TIMEZONE)
  .startOf('day')
  .format();

export const tenantUserTableFilterStore = createStore<{
  provider: string;
  subscribedUsers: boolean,
  from: string;
  to: string;
}>({
  initialValue: {
    provider: 'all',
    subscribedUsers: false,
    from: moment().utc(true).startOf('day').format(),
    to: moment().utc(true).endOf('day').format(),
  },
});
