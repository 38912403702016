import {
  Box,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useStylesCustom } from '../../../modules/dashboard/components/DashboardCardItem';
import { useAuth } from '../../../modules/auth/auth-hook';
import { SelectDefault } from '../inputs/SelectDefault';
import { useStore } from '@cobuildlab/react-simple-state';
import { fetchAuthStore } from '../../../modules/auth/auth-events';
import { useSelectedTenantMutation } from '../../types/generated';
import { WarningMaintenance } from './WarningMaintenance';

type CustomHeaderProps = {
  title: string;
  subtitle: string;
};
export const CustomHeader: React.FC<CustomHeaderProps> = ({
  title,
  subtitle,
}) => {
  const { tenantUsers, tenant: currentTenant } = useStore(fetchAuthStore);

  const classes = useStylesCustom();

  const [callSelectedTenant] = useSelectedTenantMutation({
    onCompleted: () => {
      window.location.reload();
    },
  });

  console.log({ currentTenant });
  return (
    <>
      {currentTenant?.maintenance ? (
        <WarningMaintenance
          title="En este momento este sitio se encuentra en mantenimiento."
          subtitle="Por un periodo limitado de tiempo los datos pueden no ser los correctos."
        />
      ) : null}
      <Container
        style={{ padding: '0px 0px', marginBottom: '16px' }}
        maxWidth={'lg'}
      >
        <Card
          style={{
            padding: '16px 34px',
            borderRadius: '16px',
            boxShadow: '0px 0px 8px 0px #00000014',
          }}
        >
          <CardContent className={classes.content}>
            <Stack
              display={'flex'}
              direction={{ xs: 'column-reverse', md: 'row' }}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box marginBottom={{ xs: '10px', md: '0px' }}>
                <Typography
                  marginBottom={{ xs: '5px', md: '10px' }}
                  textAlign={{ xs: 'center', md: 'start' }}
                  lineHeight={{ sx: '24px', md: '24px' }}
                  fontSize={{ sx: '16px', md: '24px' }}
                  fontWeight={700}
                >
                  {title}
                </Typography>
                <Typography
                  textAlign={{ xs: 'center', md: 'start' }}
                  color={'#7B7B7B'}
                  lineHeight={{ sx: '24px', md: '16px' }}
                  fontSize={{ sx: '14px', md: '16px' }}
                >
                  {subtitle}
                </Typography>
              </Box>
              <Box
                display={'flex'}
                sx={{ height: '45px', position: 'relative' }}
              >
                <SelectDefault
                  onChangeData={(_, value) => {
                    callSelectedTenant({
                      variables: {
                        input: {
                          tenant_id: value,
                        },
                      },
                    });
                  }}
                  value={currentTenant?.id}
                  variant="outlined"
                  options={tenantUsers.map(({ tenant }) => ({
                    value: tenant?.id as string,
                    label: tenant?.name as string,
                  }))}
                />
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};
