import { TenantUserRoles, UserRoles } from './types/generated';
import { RBACPermissions } from '../modules/rbac/rbac-types';
import timezonesList from 'timezones-list';

export const APP_ENV = process.env.REACT_APP_ENV || 'local';
export const DRAWER_WIDTH = 280;
export const MIN_DRAWER_WIDTH = 70;

export const USER_COOKIE = '_u';

export const DOMAIN_SITE = `.${process.env.REACT_APP_DOMAIN || ''}`;

export const REACT_APP_BACKEND_PATH = process.env.REACT_APP_BACKEND_PATH || '';
export const COOKIES_OPTIONS = {
  path: '/',
  domain: DOMAIN_SITE,
};

export const TENANT_ROUTES = [
  {
    path: '/tenant/:id',
    name: 'DETALLES',
    permission: RBACPermissions.TENANTS,
  },
  {
    path: '/tenant/:id/plans',
    name: 'PLANES',
    permission: RBACPermissions.TENANTS,
  },
  {
    path: '/tenant/:id/users',
    name: 'USUARIOS',
    permission: RBACPermissions.TENANTS,
  },
  {
    path: '/tenant/:id/invitations',
    name: 'INVITACIONES',
    permission: RBACPermissions.TENANTS,
  },
];

export const SETTING_ROUTES = [
  {
    path: '/settings/account',
    name: 'Cuenta',
    permission: RBACPermissions.SETTINGS,
  },
  {
    path: '/settings/plans',
    name: 'Planes',
    permission: RBACPermissions.SETTING_PLANS,
  },
  {
    path: '/settings/tenant',
    name: 'Información',
    permission: RBACPermissions.SETTING_TENANT,
  },
  {
    path: '/settings/tenant-invitations',
    name: 'Invitaciones',
    permission: RBACPermissions.SETTING_TENANT_INVITATIONS,
  },
  {
    path: '/settings/invitations',
    name: 'Invitaciones',
    permission: RBACPermissions.SETTING_INVITATIONS,
  },
  {
    path: '/settings/tenant-users',
    name: 'Usuarios',
    permission: RBACPermissions.SETTING_GLOBAL_USERS,
  },
];

export const TENANT_USER_ROLES_OPTIONS = [
  {
    value: TenantUserRoles.TenantAdministrator,
    label: 'Administrador',
  },
  {
    value: TenantUserRoles.TenantUser,
    label: 'Usuario',
  },
];

export const USER_ROLES_OPTIONS = [
  {
    value: UserRoles.Administrator,
    label: 'Administrador',
  },
  {
    value: UserRoles.User,
    label: 'Usuario',
  },
];

export const TENANT_ROLES = [
  TenantUserRoles.TenantAdministrator,
  TenantUserRoles.TenantUser,
];

export enum DateFilter {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  CURRENT_WEEK = 'currentWeek',
  LAST_WEEK = 'lastWeek',
  CURRENT_MONTH = 'currentMonth',
  LAST_MONTH = 'lastMonth',
  CURRENT_YEAR = 'currentYear',

  CUSTOM = 'custom',
  WEEK = 'week',
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

export const DASHBOARD_DATE_FILTER_OPTION = [
  {
    value: DateFilter.TODAY,
    label: 'Hoy',
  },
  {
    value: DateFilter.WEEK,
    label: 'Hace 1 semana',
  },
  {
    value: DateFilter.MONTH,
    label: 'Hace 1 mes',
  },
  {
    value: DateFilter.YEAR,
    label: 'Hace 1 año',
  },
];

export const CARD_HEIGHT_DASHBOARD = 350;

export const TIMEZONES_LIST = timezonesList
  .filter((item) => item.tzCode.includes('America'))
  .map((item) => ({
    label: item.label,
    value: item.tzCode,
  }));

export const DATE_FORMAT = 'DD-MM-YYYY HH:mm';

export const DEFAULT_TIMEZONE = 'America/Mexico_City';

export const SUBSCRIPTION_STATUSES = [
  {
    value: 'all',
    label: 'Todos',
  },
  {
    value: 'ACTIVE',
    label: 'Activo',
  },
  {
    value: 'INACTIVE',
    label: 'Inactivo',
  },
  {
    value: 'CANCELLED',
    label: 'Cancelado',
  },
];

export const SUBSCRIPTION_PAYMENT_METHOD = [
  {
    value: 'all',
    label: 'Todos',
  },
];

export const TENANT_USER_PROVIDERS = [
  {
    value: 'all',
    label: 'Todos',
  },
  {
    value: 'SYSTEM',
    label: 'Correo electronico',
  },
  {
    value: 'FACEBOOK',
    label: 'Facebook',
  },
  {
    value: 'GOOGLE',
    label: 'Google',
  },
  {
    value: 'APPLE',
    label: 'Apple',
  },
];
export const TENANT_USER_PER_PAGE_LIST = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];

export const TENANT_SUBSCRIPTION_PLANS_STATUS = [
  {
    value: 'all',
    label: 'Todos',
  },
  {
    value: 'ACTIVE',
    label: 'ACTIVE',
  },
  {
    value: 'INACTIVE',
    label: 'INACTIVE',
  },
];

export const TENANT_SUBSCRIPTION_PLANS_IS_PREMIUM = [
  {
    value: 'all',
    label: 'Todos',
  },
  {
    value: 'true',
    label: 'Premium',
  },
  {
    value: 'false',
    label: 'No premium',
  },
];

export enum MONTHS {
  JANUARY = 'JANUARY',
  FEBRUARY = 'FEBRUARY',
  MARCH = 'MARCH',
  APRIL = 'APRIL',
  MAY = 'MAY',
  JUNE = 'JUNE',
  JULY = 'JULY',
  AUGUST = 'AUGUST',
  SEPTEMBER = 'SEPTEMBER',
  OCTOBER = 'OCTOBER',
  NOVEMBER = 'NOVEMBER',
  DECEMBER = 'DECEMBER',
}

export const MONTHS_OPTIONS = {
  [MONTHS.JANUARY]: 'Enero',
  [MONTHS.FEBRUARY]: 'Febrero',
  [MONTHS.MARCH]: 'Marzo',
  [MONTHS.APRIL]: 'Abril',
  [MONTHS.MAY]: 'Mayo',
  [MONTHS.JUNE]: 'Junio',
  [MONTHS.JULY]: 'Julio',
  [MONTHS.AUGUST]: 'Agosto',
  [MONTHS.SEPTEMBER]: 'Septiembre',
  [MONTHS.OCTOBER]: 'Octubre',
  [MONTHS.NOVEMBER]: 'Noviembre',
  [MONTHS.DECEMBER]: 'Diciembre',
};