import React, { useState } from 'react';
import {
  Accordion,
  Container,
  AccordionDetails,
  AccordionSummary,
  Box,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  IconButton,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useParams } from 'react-router-dom';
import { useUserEventsQuery } from '../../shared/types/generated';
import { getFullNameEvent } from './tenant-utils';
import moment from 'moment';
import { Loading } from '../../shared/components/Loading';
import { CustomSizes } from '../../shared/components/loading-types';

const DEFAULT_ITEMS_PER_PAGE = 10;

export const TenantUserDetailsHistory: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);

  const { data, loading } = useUserEventsQuery({
    variables: {
      userId: parseInt(id as string),
    },
  });

  const histories = data?.userEvents?.events || [];
  const count = histories.length;
  const totalPages = Math.ceil(count / itemsPerPage);

  const handleChangePage = (newPage: number): void => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleChangeItemsPerPage = (event: SelectChangeEvent<number>): void => {
    setItemsPerPage(event.target.value as number);
    setPage(1);
  };

  const paginatedHistories = histories.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage,
  );

  return (
    <>
      <Container maxWidth={'lg'} sx={{ my: 5 }}>
        <Box sx={{ color: '#BBB', fontSize: 20 }}>
          Cantidad de eventos totales encontrados: {histories.length}
        </Box>
      </Container>
      <Container maxWidth={'lg'}>
        {loading ? (
          <Box
            fontSize={32}
            display="flex"
            justifyContent="center"
            alignItems="center"
            position={'absolute'}
            height={'94%'}
          >
            <Loading customSize={CustomSizes.SMALL} />
          </Box>
        ) : (
          <>
            <Box mb={1} display="flex" justifyContent="space-between">
              <Box sx={{ color: '#BBB' }}>Nombre del evento</Box>
              <Box sx={{ color: '#BBB' }}>Fecha de evento</Box>
            </Box>

            {paginatedHistories.map((history, index) => (
              <Box mb={1} key={index}>
                <Accordion>
                  <AccordionSummary>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Box sx={{ color: '#BBB' }}>
                        {getFullNameEvent(history?.name as string)}
                      </Box>
                      <Box sx={{ color: '#BBB' }}>
                        {moment(parseInt(history?.created_at as string)).format(
                          'YYYY-MM-DD HH:mm:ss',
                        )}
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box fontWeight="bold" sx={{ color: '#BBB' }}>
                      user: {history?.user_id}
                    </Box>

                    {(history?.event_meta || [])?.map((meta, metaIndex) => (
                      <Box
                        key={metaIndex}
                        sx={{ color: '#BBB' }}
                      >{`${meta?.meta_key} : ${meta?.meta_value}`}</Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}

            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              mt={2}
              sx={{
                '& .MuiSelect-select': {
                  py: 0.5,
                  pr: 4,
                },
              }}
            >
              <Box display="flex" alignItems="center" gap={1}>
                <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
                  Filas por página:
                </Box>
                <FormControl size="small" variant="standard">
                  <Select
                    value={itemsPerPage}
                    onChange={handleChangeItemsPerPage}
                    displayEmpty
                  >
                    {[5, 10, 20, 50].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box component="span" sx={{ mx: 2, whiteSpace: 'nowrap' }}>
                  {`${(page - 1) * itemsPerPage + 1}-${Math.min(page * itemsPerPage, count)} of ${count}`}
                </Box>
                <Box display="flex">
                  <IconButton
                    onClick={() => handleChangePage(page - 1)}
                    disabled={page === 1}
                    size="small"
                  >
                    <ChevronLeftIcon className="h-4 w-4" />
                  </IconButton>
                  <IconButton
                    onClick={() => handleChangePage(page + 1)}
                    disabled={page === totalPages}
                    size="small"
                  >
                    <ChevronRightIcon className="h-4 w-4" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Container>
    </>
  );
};
