import { DashboardEvents } from '../dashboard/dashboard-types';


export const getFullNameEvent = (name: string): string => {
  if (name === DashboardEvents.startSession) return 'Inicio de sesión';
  if (name === DashboardEvents.loginClicked) return 'Presionó el botón de inicio de sesión';
  if (name === DashboardEvents.registerClicked) return 'Presionó el botón de registro';

  if (name === DashboardEvents.startSessionGoogle) return 'Inicio de sesión con google';
  if (name === DashboardEvents.connectToGoogle) return 'Conexión con Google';
  if (name === DashboardEvents.gmailClicked) return 'Presionó el botón de google';
  if (name === DashboardEvents.mobileGmailClicked) return 'Presionó el botón de google (Mobile)';

  if (name === DashboardEvents.connectToApple) return 'Conexión con Apple';
  if (name === DashboardEvents.startSessionApple) return 'Inicio de sesión con Apple';
  if (name === DashboardEvents.appleClicked) return 'Presionó el botón de Apple';

  if (name === DashboardEvents.connectToFacebook) return 'Conexión con Facebook';
  if (name === DashboardEvents.startSessionFacebook) return 'Inicio de sesión con Facebook';
  if (name === DashboardEvents.facebookClicked) return 'Presionó el botón de Facebook';
  if (name === DashboardEvents.mobileFacebookClicked) return 'Presionó el botón de Facebook (Mobile)';

  if (name === DashboardEvents.wallCrashesSubscription) return 'Choque con modal de suscripción';
  if (name === DashboardEvents.wallCrashesRegister) return 'Choque con modal de registro';
  if (name === DashboardEvents.wallCrashesFreeSubscription) return 'Choque con modal de suscripción gratuito';

  if (name === DashboardEvents.mobileWallCrashesFreeSubscription) return 'Choque con modal de suscripción gratuito (MobiLe)';
  if (name === DashboardEvents.mobileWallCrashesSubscription) return 'Choque con modal de suscripción (MobiLe)';
  if (name === DashboardEvents.mobileWallCrashesRegister) return 'Choque con modal de registro (MobiLe)';

  if (name === DashboardEvents.registerUser) return 'Usuario registrado';
  if (name === DashboardEvents.pageVisit) return 'Visita de pagina';

  return 'Autenticación';

};