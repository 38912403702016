import React, { useEffect, useState } from 'react';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { changeDashboardDateFilterStore } from '../../dashboard-events';
import { DashboardCardItem } from '../DashboardCardItem';
import { getSubscriptionRenewTitleText } from '../../dashboard-utils';
import {
  fetchSubscriptionRenewCount,
} from '../../../subscription/subscription-actions';
import { useAuth } from '../../../auth/auth-hook';

export const DashboardTotalSubscriptionActiveItem: React.FC = () => {
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);
  const { tenant } = useAuth();
  const [count, setCount] = useState(0);

  const [callAction, loading] = useCallAction(fetchSubscriptionRenewCount, {
    onCompleted: (data) => {
      setCount(
        data.reduce((acc, curr) => {
          return acc + curr.count;
        }, 0),
      );
    },
  });

  useEffect(() => {
    if (tenant) {
      callAction({
        tenant,
        from,
        to,
        filter: filter,
      });
    }
  }, [tenant, callAction, from, to, filter]);

  return (
    <DashboardCardItem
      title={getSubscriptionRenewTitleText(from, to, filter)}
      content={count}
      loading={loading}
    />
  );
};
