import React, { useEffect } from 'react';
import { DashboardCardItem } from '../DashboardCardItem';
import { useUserSessionCountLazyQuery } from '../../../../shared/types/generated';
import { useAuth } from '../../../auth/auth-hook';
import { useStore } from '@cobuildlab/react-simple-state';
import { changeDashboardDateFilterStore } from '../../dashboard-events';
import { getDateTitleText } from '../../dashboard-utils';

export const DashboardAnonymousUserSessionCount: React.FC = () => {
  const { tenant } = useAuth();
  const [callAction, { loading, data }] = useUserSessionCountLazyQuery();
  const { from, to , filter} = useStore(changeDashboardDateFilterStore);

  useEffect(() => {
    callAction({
      variables: {
        where: {
          from, to,
          tenantId: tenant?.id,
        },
      },
    });

  }, [callAction, tenant?.id, from, to]);


  return (
    <DashboardCardItem
      title={`Usuarios anonimos activos ${getDateTitleText(from, to, filter)}`}
      content={data?.userSessionCount?.count || 0}
      loading={loading}
    />
  );
};
