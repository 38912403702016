import React, { useEffect, useState } from 'react';
import { TableDefaultSticky } from '../../../../shared/components/table/TableDefaultSticky';
import { TableColumnType } from '../../../../shared/components/table/table-types';
import {
  useCallAction,
  useEvent,
  useStore,
} from '@cobuildlab/react-simple-state';
import { useAuth } from '../../../auth/auth-hook';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { subscriptionStatus } from '../../dashboard-utils';
import { fetchSubscriptionsNextExpireByMonthEvent } from '../../../subscription/subscription-events';
import { fetchSubscriptionsNextExpireByMonth } from '../../../subscription/subscription-actions';
import { GlobalSubscriptionTable } from '../../../subscription/subscription-types';
import { subscriptionNextExpireTableFilterStore } from '../../../subscription/subscription-store';
import { useStylesCustom } from '../DashboardCardItem';
import { useTableSort } from '../../../../shared/components/table/useTableSort';
import { SubscriptionUserModal } from './SubscriptionUserModal';
import { getDateByTimezone } from '../../../../shared/utils';
import { SubscriptionNextExpireTableFilter } from '../../../subscription/components/SubscriptionNextExpireTableFilter';

export const DashboardSubscriptionsNextExpiredTable: React.FC = () => {
  const classes = useStylesCustom();
  const [openModal, setOpenModal] = useState(false);
  const [currentUserSubscription, setCurrentUserSubscription] =
    useState<GlobalSubscriptionTable>();
  const { data, total, paymentMethods } = useEvent(
    fetchSubscriptionsNextExpireByMonthEvent,
  );

  const [callFetchSubscriptionsNextExpire, loading] = useCallAction(
    fetchSubscriptionsNextExpireByMonth,
  );

  const { monthExpire, status, paymentMethod } = useStore(subscriptionNextExpireTableFilterStore);
  const { sort, handleSort } = useTableSort();

  const { tenant } = useAuth();

  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });

  useEffect(() => {
    if (tenant) {
      callFetchSubscriptionsNextExpire({
        tenant,
        page: pagination.page - 1,
        monthExpire,
        status,
        paymentMethod,
        orderBy: sort,
      });
    }
  }, [
    callFetchSubscriptionsNextExpire,
    pagination.page,
    tenant,
    monthExpire,
    status,
    paymentMethod,
    sort,
  ]);

  const handleOpenModal = (item: GlobalSubscriptionTable): void => {
    setCurrentUserSubscription(item);
    setOpenModal(true);
  };

  const COLUMNS: TableColumnType<GlobalSubscriptionTable>[] = [
    {
      columnName: 'ID',
      columnValue: (item) => item.id,
      sortableField: 'id',
    },
    {
      columnName: 'NOMBRE',
      columnValue: null,

      columnComponent: (item) => <Box textAlign="left">{item.user_name}</Box>,
      sortableField: 'user_name',
    },
    {
      columnName: 'EMAIL',
      columnValue: null,

      columnComponent: (item) => (
        <Box textAlign="left">{item.user_email as string}</Box>
      ),
      sortableField: 'user_email',
    },
    {
      columnName: 'PLAN',
      columnValue: null,

      columnComponent: (item) => (
        <Box textAlign="left">{item.plan_title as string}</Box>
      ),
      sortableField: 'plan_title',
    },
    {
      columnName: 'N° DE SUSCRIPCIÓN',
      columnValue: null,

      columnComponent: (item) => (
        <Box textAlign="left">{item.transaction_id as string}</Box>
      ),
      sortableField: 'transaction_id',
    },
    {
      columnName: 'FECHA DE SUSCRIPCIÓN',
      columnValue: null,
      columnComponent: (item) => (
        <Box>
          {getDateByTimezone(
            item.created_at as string,
            tenant?.timezone as string,
          )}
        </Box>
      ),
      sortableField: 'period_start_date',
    },
    {
      columnName: 'FECHA DE VENCIMIENTO',
      columnValue: null,

      columnComponent: (item) => (
        <Box>
          {getDateByTimezone(
            item.period_end_date as string,
            tenant?.timezone as string,
          )}
        </Box>
      ),
      sortableField: 'period_end_date',
    },
    {
      columnName: 'MÉTODO DE PAGO',
      columnValue: null,

      columnComponent: (item) => (
        <Box textAlign="left">{item.payment_method as string}</Box>
      ),
      sortableField: 'payment_method',
    },
    {
      columnName: 'STATUS',
      columnValue: null,
      columnComponent: (item) => (
        <Box textAlign="left">{subscriptionStatus(item.status)}</Box>
      ),
      sortableField: 'status',
    },
  ];

  return (
    <>
      <SubscriptionUserModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        userSubscription={currentUserSubscription}
      />
      <Box
        marginY={2}
        sx={{
          display: { xs: 'block', md: 'flex' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography
          component={'div'}
          sx={{ marginBottom: { xs: '10px', md: '0px' } }}
          fontSize={20}
          fontWeight={700}>
          Suscripciones próximas a renovar
        </Typography>
        <Box>
          <SubscriptionNextExpireTableFilter
            paymentMethodFilters={paymentMethods || []}
          />
        </Box>
      </Box>
      <Card
        elevation={2}
        style={{
          border: '1px solid #BBBBBB',
          padding: '16px',
          borderRadius: '16px',
          boxShadow: '0px 0px 8px 0px #00000014',
        }}>
        <CardContent className={classes.content}>
          <TableDefaultSticky
            count={total}
            items={data}
            columns={COLUMNS}
            page={pagination.page}
            pageSize={pagination.pageSize}
            loading={loading}
            messageLoading={'Cargando subscripciones proximas a vencer...'}
            handleClickRow={(item) => handleOpenModal(item)}
            handleChangePage={(page) => {
              if (tenant) {
                setPagination({
                  ...pagination,
                  page: page + 1,
                });
              }
            }}
            handleSort={handleSort}
          />
        </CardContent>
      </Card>
    </>
  );
};
